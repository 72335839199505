import AddItem from "../actions/AddItem";
import SetMenu from "../actions/setMenu";

export const mapStateToProps = state => ({
  restaurantOpen: state.config.availability.restaurant,
  closedMessage: state.config.availability.closedMessage,
  redirectToNewWebsite: state.config.availability.redirectToNewWebsite,
  menu: state.menu,
});

export const mapDispatchToProps = dispatch => ({
  addItem: item => {
    dispatch(AddItem(item));
  },
  setMenu: menu => {
    dispatch(SetMenu(menu));
  },
});
