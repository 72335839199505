const getDay = require("date-fns/get_day");
const isWithinRange = require("date-fns/is_within_range");

function timeCheck() {
  const today = new Date();
  const day = getDay(today);
  if (day !== 2) {
    const start = new Date();
    const end = new Date();
    start.setHours(10);
    start.setMinutes(30);
    end.setHours(21);
    end.setMinutes(59);
    return isWithinRange(today, start, end);
  }

  return false;
}

export default timeCheck;
