import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";
import rnChoices from "../rnChoices";

export default function comboRNDialog(props) {
  const { closeNoSave, closeSave, open } = props;
  return (
    <div>
      <Dialog
        aria-labelledby="Rice / Noodle Choice"
        open={open}
        onClose={closeNoSave}
      >
        <DialogTitle id="Rice / Noodle Choicee">
          Rice / Noodle Choice
        </DialogTitle>
        <div>
          <List>
            {rnChoices.map(choice => {
              const { name, price } = choice;
              const displayString = `${name} (+$${price.toFixed(2)})`;
              return (
                <ListItem button key={name} onClick={() => closeSave(name)}>
                  <ListItemText primary={displayString} />
                </ListItem>
              );
            })}
          </List>
        </div>
      </Dialog>
    </div>
  );
}

comboRNDialog.propTypes = {
  closeNoSave: PropTypes.func.isRequired,
  closeSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
