import React, { Component } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreOutlined";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import spicy from "../../../images/spicy.svg";
import "../../../styles/menu.css";
import RiceDialog from "./comboRNDialog";
import SideDialog from "./comboSideDialog";
import timeCheck from "../timeCheck";
import rnChoices from "../rnChoices";

export default class collapsible extends Component {
  constructor(props) {
    super(props);
    this.state = {
      riceModalIsOpen: false,
      sideModalIsOpen: false,
      entry: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { riceModalIsOpen, sideModalIsOpen } = this.state;
    if (nextState.riceModalIsOpen !== riceModalIsOpen) {
      return true;
    }
    if (nextState.sideModalIsOpen !== sideModalIsOpen) {
      return true;
    }
    return false;
  }

  addItem = (name, price, category) => {
    const { sauces } = this.props;
    if (timeCheck()) {
      this.setState({
        riceModalIsOpen: true,
        entry: {
          name,
          price,
          category,
          ...sauces,
        },
      });
    } else {
      this.failureNotification();
    }
  };

  closeRiceWithoutSave = () => {
    this.setState({ riceModalIsOpen: false });
  };

  closeRiceWithSave = value => {
    const { entry } = this.state;
    let newEntry = Object.assign({}, entry);
    const item = rnChoices.find(element => element.name === value);
    newEntry = Object.assign({}, entry, {
      rnChoice: item.name,
      rnPrice: item.price,
    });
    console.log(newEntry);
    this.setState({
      riceModalIsOpen: false,
      sideModalIsOpen: true,
      entry: newEntry,
    });
  };

  closeSideWithoutSave = () => {
    this.setState({
      riceModalIsOpen: false,
      sideModalIsOpen: false,
      entry: null,
    });
  };

  closeSideWithSave = value => {
    const { entry } = this.state;
    const { addItem } = this.props;
    const newEntry = Object.assign({}, entry, {
      sideChoice: value,
    });
    this.successNotification(
      `${newEntry.name} w/ ${newEntry.rnChoice} + ${value}`
    );
    this.setState(
      {
        riceModalIsOpen: false,
        sideModalIsOpen: false,
        entry: null,
      },
      () => addItem(newEntry)
    );
  };

  successNotification = name =>
    toast.success(`${name} added to order!`, {
      position: toast.POSITION.BOTTOM_RIGHT,
      draggablePercent: 66,
    });

  failureNotification = () =>
    toast.error(
      `Online ordering is unavavilable right now. Please try again after 1030 AM.`,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        draggablePercent: 66,
      }
    );

  render() {
    const { items } = this.props;
    const { Category, CategoryDesc } = items[0];
    const { riceModalIsOpen, sideModalIsOpen } = this.state;
    const Items = items
      .sort((a, b) => (a.Name > b.Name ? 1 : -1))
      .map(item => (
        <div key={item.Name}>
          <ListItem divider>
            <ListItemText
              primary={
                item.isSpicy ? (
                  <span className="spicySpan">
                    <img
                      src={spicy}
                      alt="This item is spicy"
                      style={{ width: 16, height: 16 }}
                    />
                    {item.Name} - ${parseFloat(item.Price).toFixed(2)}
                  </span>
                ) : (
                  <span className="regularSpan">
                    {item.Name} - ${parseFloat(item.Price).toFixed(2)}
                  </span>
                )
              }
              secondary={
                <span style={{ fontFamily: "IBM Plex Sans" }}>
                  <i>{item.ItemDescription}</i>
                </span>
              }
            />
            <Button
              variant="contained"
              style={{
                backgroundColor: "#F50057",
                color: "rgb(252,252,252)",
              }}
              size="small"
              onClick={() => this.addItem(item.Name, item.Price, item.Category)}
            >
              <span style={{ fontSize: "0.88em" }}>
                <b>ORDER</b>
              </span>
            </Button>
          </ListItem>
        </div>
      ));
    return (
      <ExpansionPanel
        style={{
          backgroundColor: "#A0A0A0",
          width: "88%",
          margin: "auto",
        }}
      >
        <ExpansionPanelSummary
          expandIcon={
            <ExpandMoreIcon
              style={{ color: "#080808", transform: "scale(1.22)" }}
            />
          }
        >
          <Typography variant="h6" style={{ fontFamily: "IBM Plex Sans" }}>
            {Category}
          </Typography>
        </ExpansionPanelSummary>
        <div style={{ backgroundColor: "#D8D8D8" }}>
          <List
            subheader={
              <ListSubheader
                disableSticky
                style={{
                  textAlign: "center",
                  fontSize: "1.08em",
                  color: "#080808",
                  fontFamily: "IBM Plex Sans",
                }}
              >
                {CategoryDesc && CategoryDesc}
              </ListSubheader>
            }
            style={{ width: "96%", margin: "auto" }}
          >
            {Items}
          </List>
        </div>
        <RiceDialog
          open={riceModalIsOpen}
          closeNoSave={this.closeRiceWithoutSave}
          closeSave={this.closeRiceWithSave}
        />
        <SideDialog
          open={sideModalIsOpen}
          closeNoSave={this.closeSideWithoutSave}
          closeSave={this.closeSideWithSave}
        />
      </ExpansionPanel>
    );
  }
}
