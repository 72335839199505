import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreOutlined";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import PropTypes from "prop-types";
import spicy from "../../../images/spicy.svg";
import noRawFish from "../../../images/checkmark.svg";
import "../../../styles/menu.css";

const nanoid = require("nanoid");

export default function BasicMenu(props) {
  const { items, addItem } = props;
  const { Category, CategoryDesc } = items[0];
  const Items = items
    .sort((a, b) => (a.Name > b.Name ? 1 : -1))
    .map(item => {
      if (item.LargePrice) {
        return (
          <div key={nanoid()}>
            <ListItem>
              <ListItemText
                primary={
                  item.isSpicy ? (
                    <span className="spicySpan">
                      <img
                        src={spicy}
                        alt="This item is spicy"
                        style={{ width: 16, height: 16 }}
                      />
                      {item.Name}
                    </span>
                  ) : (
                    <span className="regularSpan">{item.Name}</span>
                  )
                }
                secondary={
                  <span style={{ fontFamily: "IBM Plex Sans" }}>
                    <i>{item.ItemDescription}</i>
                  </span>
                }
              />
            </ListItem>
            {item.SmallPrice && (
              <ListItem>
                <ListItemText
                  primary={`- Pint: $${item.SmallPrice.toFixed(2)}`}
                />

                <Button
                  style={{
                    backgroundColor: "#F50057",
                    color: "rgb(252,252,252)",
                  }}
                  size="small"
                  variant="contained"
                  onClick={() =>
                    addItem(`Pint ${item.Name}`, item.SmallPrice, item.Category)
                  }
                >
                  <span
                    style={{
                      fontSize: "0.88em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    <b>ORDER</b>
                  </span>
                </Button>
              </ListItem>
            )}
            <ListItem divider>
              <ListItemText
                primary={`- Quart: $${item.LargePrice.toFixed(2)}`}
              />
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#F50057",
                  color: "rgb(252,252,252)",
                }}
                size="small"
                onClick={() =>
                  addItem(`Quart ${item.Name}`, item.LargePrice, item.Category)
                }
              >
                <span
                  style={{ fontSize: "0.88em", fontFamily: "IBM Plex Sans" }}
                >
                  <b>ORDER</b>
                </span>
              </Button>
            </ListItem>
          </div>
        );
      }
      return (
        <div key={nanoid()}>
          <ListItem divider>
            <ListItemText
              primary={
                item.isSpicy ? (
                  <span className="spicySpan">
                    <img
                      src={spicy}
                      alt="This item is spicy"
                      style={{ width: 16, height: 16 }}
                    />
                    {item.noRawFish && (
                      <img
                        src={noRawFish}
                        alt="This item contains no raw fish"
                        style={{ width: 16, height: 16 }}
                      />
                    )}
                    {item.Name} - ${parseFloat(item.Price).toFixed(2)}
                  </span>
                ) : (
                  <span className="regularSpan">
                    {item.noRawFish && (
                      <img
                        src={noRawFish}
                        alt="This item contains no raw fish"
                        style={{ width: 16, height: 16 }}
                      />
                    )}
                    {item.Name} - ${parseFloat(item.Price).toFixed(2)}
                  </span>
                )
              }
              secondary={
                <span style={{ fontFamily: "IBM Plex Sans" }}>
                  <i>{item.ItemDescription}</i>
                </span>
              }
            />
            <Button
              variant="contained"
              style={{
                backgroundColor: "#F50057",
                color: "rgb(252,252,252)",
              }}
              size="small"
              onClick={() => addItem(item.Name, item.Price, item.Category)}
            >
              <span style={{ fontSize: "0.88em", fontFamily: "IBM Plex Sans" }}>
                <b>ORDER</b>
              </span>
            </Button>
          </ListItem>
        </div>
      );
    });
  return (
    <ExpansionPanel
      style={{
        backgroundColor: "#A0A0A0",
        width: "88%",
        margin: "auto",
      }}
    >
      <ExpansionPanelSummary
        expandIcon={
          <ExpandMoreIcon
            style={{ color: "#080808", transform: "scale(1.22)" }}
          />
        }
      >
        <Typography variant="h6" style={{ fontFamily: "IBM Plex Sans" }}>
          {Category}
        </Typography>
      </ExpansionPanelSummary>
      <div style={{ backgroundColor: "#D8D8D8" }}>
        <List
          subheader={
            <ListSubheader
              disableSticky
              style={{
                textAlign: "center",
                fontSize: "1.08em",
                color: "#080808",
                fontFamily: "IBM Plex Sans",
              }}
            >
              {CategoryDesc && CategoryDesc}
            </ListSubheader>
          }
          style={{ width: "96%", margin: "auto" }}
        >
          {Items}
        </List>
      </div>
    </ExpansionPanel>
  );
}

BasicMenu.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  addItem: PropTypes.func.isRequired,
};
