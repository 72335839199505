import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";

export default function chefsSpecialtyDialog(props) {
  const { closeNoSave, closeSave, open } = props;
  const choices = ["Seaweed Salad", "Miso Soup"];
  return (
    <div>
      <Dialog aria-labelledby="Rice Choice" open={open} onClose={closeNoSave}>
        <DialogTitle id="Rice Choice">Side Choice</DialogTitle>
        <div>
          <List>
            {choices.map(c => (
              <ListItem button onClick={() => closeSave(c)}>
                <ListItemText primary={c} />
              </ListItem>
            ))}
          </List>
        </div>
      </Dialog>
    </div>
  );
}

chefsSpecialtyDialog.propTypes = {
  closeNoSave: PropTypes.func.isRequired,
  closeSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
