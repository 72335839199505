import React, { useEffect } from "react";
import { Link, navigate } from "gatsby";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Layout from "../components/layout/layout";
import timeCheck from "../components/menu/timeCheck";
import BasicMenu from "../components/menu/category/BasicMenu";
import SushiEntreesMenu from "../components/menu/sushiEntree/sushiEntrees";
import ChefsSpecialtyMenu from "../components/menu/chefsSpecialty/chefsSpecialtyMenu";
import ComboMenu from "../components/menu/combo/comboMenu";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../state/mapStateAndDispatch/Menu";
import "react-toastify/dist/ReactToastify.css";
import "../styles/buttons.css";

const sauces = {
  sauces: {
    eelSauce: {
      number: 0,
      price: 0,
    },
    spicyMayo: {
      number: 0,
      price: 0,
    },
    teriyakiSauce: {
      number: 0,
      price: 0,
    },
    bourbonSauce: {
      number: 0,
      price: 0,
    },
    sweetSourSauce: {
      number: 0,
      price: 0,
    },
    dumplingSauce: {
      number: 0,
      price: 0,
    },
  },
};

function menu(props) {
  const {
    addItem,
    restaurantOpen,
    closedMessage,
    menu: Menu,
    redirectToNewWebsite,
  } = props;

  useEffect(() => {
    if (redirectToNewWebsite === true) {
      navigate("https://getfoodweb.com/store/2-chopstick-express");
    }
  }, [redirectToNewWebsite]);

  if (redirectToNewWebsite === undefined || redirectToNewWebsite === true) {
    return <div />;
  }

  function successNotification(name) {
    toast.success(`${name} added to order!`, {
      position: toast.POSITION.BOTTOM_RIGHT,
      draggablePercent: 66,
    });
  }

  function failureNotification() {
    toast.error(
      `Online ordering is unavavilable right now. Please try again after 10:30 AM.`,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        draggablePercent: 66,
      }
    );
  }

  function onClick(name, price, category) {
    if (timeCheck() === true && restaurantOpen) {
      const entry = {
        name,
        price,
        category,
        ...sauces,
      };
      addItem(entry);
      successNotification(name);
    } else {
      failureNotification();
    }
  }

  return (
    <div>
      <Helmet>
        <title>Chopstick Express | Menu | St. Petersburg, FL</title>
        <meta charset="utf-8" />
        <meta
          name="description"
          content="Browse the Chopstick Express Menu and order Chinese food online now for fast delivery or pickup."
        />
        <link rel="canonical" href="https://www.chopstickfl.com/menu" />
      </Helmet>
      {Menu && (
        <Layout>
          <div
            style={{ backgroundColor: "rgb(252,252,252)", marginBottom: -8 }}
          >
            {restaurantOpen ? (
              <div>
                <br />
                <h1>Chopstick Express Menu</h1>
                <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                  <p>
                    Get 5/10 free Krab Rangoons when you pay with cash on orders
                    over $25/$50!
                  </p>
                </div>
                <br />
                {Object.keys(Menu)
                  .sort()
                  .map(key => {
                    const value = Menu[key];
                    let view;
                    if (key === "ChefsSpecialties") {
                      view = (
                        <ChefsSpecialtyMenu
                          items={value}
                          sauces={sauces}
                          addItem={addItem}
                        />
                      );
                    } else if (key === "Combo") {
                      view = (
                        <ComboMenu
                          items={value}
                          sauces={sauces}
                          addItem={addItem}
                        />
                      );
                    } else if (key === "SushiEntrees") {
                      view = (
                        <SushiEntreesMenu
                          items={value}
                          sauces={sauces}
                          addItem={addItem}
                        />
                      );
                    } else {
                      view = <BasicMenu items={value} addItem={onClick} />;
                    }
                    return (
                      <div key={key}>
                        {view}
                        <br />
                        <br />
                        <br />
                      </div>
                    );
                  })}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Link className="bigRedButton" to="/cart">
                    View Cart
                  </Link>
                </div>
                <br />
                <br />
                <br />
              </div>
            ) : (
              <div
                style={{
                  height: "75vh",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  alignItems: "center",
                  backgroundColor: "rgb(252,252,252)",
                  marginBottom: -8,
                }}
              >
                <h1>
                  <br />
                  <p>{closedMessage}</p>
                </h1>
              </div>
            )}
          </div>
          <ToastContainer hideProgressBar autoClose={2222} />
        </Layout>
      )}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(menu);
