import React, { useState } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreOutlined";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import "../../../styles/menu.css";
import Dialog from "./sushiEntreesDialog";
import timeCheck from "../timeCheck";

export default function sushiEntrees(props) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [entry, setEntry] = useState(null);

  const addItem = (name, price, category) => {
    const { sauces } = props;
    if (timeCheck()) {
      setModalIsOpen(true);
      setEntry({
        name,
        price,
        category,
        ...sauces,
      });
    } else {
      toast.error(
        `Online ordering is unavavilable right now. Please try again after 1030 AM.`,
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          draggablePercent: 66,
        }
      );
    }
  };

  const closeModalWithoutSave = () => {
    setModalIsOpen(false);
  };

  const closeModalWithSave = value => {
    const { addItem } = props;
    const newEntry = Object.assign({}, entry, {
      side: value,
    });
    toast.success(`${newEntry.name} w/ ${value} added to order!`, {
      position: toast.POSITION.BOTTOM_RIGHT,
      draggablePercent: 66,
    });
    setModalIsOpen(false);
    setEntry(null);
    addItem(newEntry);
  };

  const { items } = props;
  const { Category, CategoryDesc } = items[0];
  const Items = items
    .sort((a, b) => (a.Name > b.Name ? 1 : -1))
    .map(item => (
      <div key={item.Name}>
        <ListItem divider>
          <ListItemText
            primary={
              <span className="regularSpan">
                {item.Name} - ${parseFloat(item.Price).toFixed(2)}
              </span>
            }
            secondary={
              <span style={{ fontFamily: "IBM Plex Sans" }}>
                <i>{item.ItemDescription}</i>
              </span>
            }
          />
          <Button
            variant="contained"
            style={{
              backgroundColor: "#F50057",
              color: "rgb(252,252,252)",
            }}
            size="small"
            onClick={() => addItem(item.Name, item.Price, item.Category)}
          >
            <span style={{ fontSize: "0.88em", fontFamily: "IBM Plex Sans" }}>
              <b>ORDER</b>
            </span>
          </Button>
        </ListItem>
      </div>
    ));

  return (
    <ExpansionPanel
      style={{
        backgroundColor: "#A0A0A0",
        width: "88%",
        margin: "auto",
      }}
    >
      <ExpansionPanelSummary
        expandIcon={
          <ExpandMoreIcon
            style={{ color: "#080808", transform: "scale(1.22)" }}
          />
        }
      >
        <Typography variant="h6" style={{ fontFamily: "IBM Plex Sans" }}>
          {Category}
        </Typography>
      </ExpansionPanelSummary>
      <div style={{ backgroundColor: "#D8D8D8" }}>
        <List
          subheader={
            <ListSubheader
              disableSticky
              style={{
                textAlign: "center",
                fontSize: "1.08em",
                color: "#080808",
                fontFamily: "IBM Plex Sans",
              }}
            >
              {CategoryDesc && CategoryDesc}
            </ListSubheader>
          }
          style={{ width: "96%", margin: "auto" }}
        >
          {Items}
        </List>
      </div>
      <Dialog
        open={modalIsOpen}
        closeNoSave={closeModalWithoutSave}
        closeSave={closeModalWithSave}
      />
    </ExpansionPanel>
  );
}
